@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
// Vex Core
@import "./@vex/styles/core";
.mat-slide-toggle-thumb {
  width: 10px !important;
  height: 10px !important;
  transform: translate(50%, 50%);
}

.mat-slide-toggle-bar {
background-color: rgb(238, 0, 0);
border-radius: 15px !important;
height: 16px !important;
}

.mat-slide-toggle-thumb-container {
top: -2px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
background-color: rgb(0, 102, 17);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
background-color: rgb(255, 255, 255);
}
/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}
